import React, {Component} from 'react';

export default class Service extends Component {

  render() {
    return (
      <div className="container" id="service">
        <div className="inner-container">
          <div className="divider"></div>
          <h2>서비스</h2>
          <div className="divider"></div>

          <p style={{textAlign: "center", wordBreak: "keep-all"}}>
            포애퍼에서 직접 개발하고 운영중인 서비스들입니다.
          </p>

          <div className="h-divider"></div>

          <div className="service-item">
            <div style={{display: "flex", alignItems: "center"}}>
              <img className="service-icon" src="img/posikyi-icon.png"/>
              <div style={{marginLeft: "1.5rem"}}>
                <h3 className="service-title">포식이
                  <a className="market-btn" href="https://play.google.com/store/apps/details?id=com.poapper.posikyi"><i className="fab fa-google-play"></i></a><a className="market-btn" href="https://apps.apple.com/kr/app/id1548629051"><i className="fab fa-apple"></i></a></h3>
                <h4 className="service-subtitle">모바일 식권 서비스 with POSTECH</h4>
              </div>
            </div>

            <p className="service-desc">포식이는 '포스텍(POSTECH)' + '식사'를 합친 단어로, <b>대학 맞춤형 학식 통합 결제 시스템</b> 입니다.<br/>
              포스텍 구성원들을 위한 서비스로 POSTECH 통합로그인(SSO)와 연동되어있습니다.<br/>
              기존 학생 정식 식권 모바일화 뿐만 아니라, 밀플랜, 식단표, 도시락 주문 등 포스텍 복지회에서 서비스하는 다양한 기능들을 포함하고 있습니다.<br/><br/>
              <em>* 포스텍 지곡회관 해동 아우름홀 및 위즈덤에서 시범 운영 (21.2.1 ~ 22.1.31) *</em>
            </p>

            <center>
              <img className="service-img" src="/img/posikyi-screenshot.png"/>
            </center>
          </div>

          <div className="h-divider"></div>

          <div className="service-item">
            <div style={{display: "flex", alignItems: "center"}}>
              <img className="service-icon" src="img/ssp-icon.png"/>
              <div style={{marginLeft: "1.5rem"}}>
                <h3 className="service-title">SSP
                  <a className="market-btn" href="https://play.google.com/store/apps/details?id=com.poapper.ssp"><i className="fab fa-google-play"></i></a>
                  <a className="market-btn" href="https://apps.apple.com/kr/app/SSP/id1582025618"><i className="fab fa-apple"></i></a>
                  <a className="market-btn" href="https://poscossp.com"><i className="fas fa-globe"></i></a>
                </h3>
                <h4 className="service-subtitle">벤처기업 IR 플랫폼 with POSCO</h4>
              </div>
            </div>

            <p className="service-desc">SSP는 포스코 벤처기업 지원프로그램(Startup Support Program)의 Digital 플랫폼으로 POSCO, 파트너, VC들이 함께하는 <b>벤처들의 플레이그라운드</b> 입니다.<br/>
              영상 기반의 간편한 IR., 벤처 기업들의 네트워킹, 마켓, 채팅으로 쉽고 빠른 연결, 포스코와 함께하는 다양한 프로그램, 중요한 사업 공지도 한 곳에서!<br/><br/>
              지금 바로 Digital Venture Valley SSP에서 창업의 시작을 경험해보세요!
            </p>

            <center>
              {/*<img className="service-img" src="img/posikyi-screenshot.png"/>*/}
            </center>
          </div>
        </div>
      </div>
    );
  }
}