import React, {Component} from 'react';

export default class PersonCard extends Component {

  constructor(props) {
    super(props);
  }
  
  render() {
    return (
      <div className="column-3">
        {/* style={{filter: this.props.is_active || "blur(3px)"}}
            style={{filter: this.props.is_active || "opacity(0.7)"}} */}
        <div className="name-card">
          <div className="card-content">
            <div className="nc-role">{this.props.role}</div>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <span style={{width: '8px', height: '8px', marginRight: '8px', borderRadius: '10px', background: this.props.is_active ? '#6cdb9d' : '#ff4f81'}}/>
              <div className="nc-name">{this.props.name} <span className="nc-name-eng">{this.props.name_eng}</span></div>
            </div>
            <div style={{marginTop: "0.5em"}}>
              {
                this.props.job.split('\n').map((str) => {
                  return (<div>{str}</div>)
                })
              }
            </div>
            <br/>
            <a href={`https://github.com/${this.props.githubId}`}><i className="fab fa-github"/> GitHub</a>
          </div>
        </div>
      </div>
    );
  }
}