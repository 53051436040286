import React, {Component} from 'react';

export default class Footer extends Component {
  render() {
    return (
      <div className="footer">
        <div className="container">
          <div className="inner-container">
            <img height="48" src="img/poapper-logo.svg"/>
            <p className="company-info">
              ㈜포애퍼 ﹒ 포항시 남구 청암로 87, 체인지업 그라운드 508호 ﹒ 대표 박원빈 ﹒ 사업자 번호 404-81-46643
            </p>
            <p className="copyright">Copyright ⓒ PoApper, Inc. All rights reserved.</p>  
          </div>
        </div>
      </div>
    );
  }
}

