import React, {Component} from 'react';

export default class HomeImage extends Component {
  render() {
    return (
      <div className="main-img-box">
        <div className="kenburns-top" style={{width: '100%', height: '100%'}}>
          <img className="main-img" src="https://cdn.pixabay.com/photo/2016/11/19/14/00/code-1839406_960_720.jpg"/>
        </div>
          <div className="container" style={{position: "absolute"}}>
            <div style={{padding: "2rem"}}>
              <h1 style={{color: "white", fontSize: "2.2em", fontWeight: "500"}}>Talk is cheap. Show me the code. <span
                style={{fontSize: "0.5em", fontWeight: "300",}}>- Linus Torvalds</span></h1>
              <div className="main-divider"/>
              <p style={{color: "white"}}>좋은 개발 문화를 이끌어나가겠습니다.</p>
            </div>
          </div>
          <img className="main-wave" src="img/wave.svg"/>
      </div>
    );
  }
}

