import React, {Component} from 'react';
import PersonCard from "./personCard";

export default class Introduce extends Component {
  render() {
    return (
      <div className="container" id="intro">
        <div className="inner-container">
          <div className="divider"/>
          <h2>포애퍼 소개</h2>
          <div className="divider"/>

          <p style={{textAlign: "center", wordBreak: "keep-all"}}>
            포스텍 개발자 네트워크 동아리로 출발한 포애퍼는, 포스텍 최고의 개발자들로 구성,
            2020년 9월 주식회사 포애퍼로 법인화 되었습니다.</p>

          <div className="h-divider"/>
          <h3>우리는 무엇을 하나요?</h3>

          <div className="columns">
            <div className="column">
              <div className="card p-red">
                <div className="card-content">
                  <div className="card-title">
                    서비스 개발
                  </div>
                  <div>
                    포스텍 모바일 식권 앱 '포식이' 등<br/>
                    서비스들을 직접 제작하여 운영하고 있습니다.
                  </div>
                  <img className="card-img" src="img/service.svg"/>
                </div>
              </div>
            </div>
            <div className="column">
              <div className="card p-green">
                <div className="card-content">
                  <div className="card-title">
                    소프트웨어 외주
                  </div>
                  <div>
                    웹 사이트, 어플리케이션, 프로그램 제작 등 다양한<br/>
                    소프트웨어 개발 관련 외주를 받고있습니다.
                  </div>
                  <img className="card-img" src="img/outsourcing.svg"/>
                </div>
              </div>
            </div>
            <div className="column">
              <div className="card p-blue">
                <div className="card-content">
                  <div className="card-title">
                    데이터 센터 운영
                  </div>
                  <div>
                    POSTECH 학생식당 메뉴 등의 다양한 데이터를 관리하는<br/>
                    PoDAC 데이터 센터를 운영하고 있습니다.
                  </div>
                  <img className="card-img" src="img/data.svg"/>
                </div>
              </div>
            </div>
            <div className="column">
              <div className="card p-yellow">
                <div className="card-content">
                  <div className="card-title">
                    What's Next?
                  </div>
                  <div>...<br/>&nbsp;</div>
                  <img className="card-img" src="img/question.svg"/>
                </div>
              </div>
            </div>
          </div>

          <div className="h-divider"/>
          <h3>팀원을 소개합니다!</h3>
          <div style={{padding: '0 2rem', display: 'flex', justifyContent: 'flex-end', fontSize: '0.9em'}}>
            <div style={{display: 'flex', alignItems: 'center', color: '#6cdb9d', fontWeight: 'bold', marginRight: '16px'}}><div style={{width: '8px', height: '8px', marginRight: '10px', borderRadius: '10px', background: '#6cdb9d'}}/> Active</div>
            <div style={{display: 'flex', alignItems: 'center',  color: '#ff4f81', fontWeight: 'bold'}}><div style={{width: '8px', height: '8px', marginRight: '10px', borderRadius: '10px', background: '#ff4f81'}}/> Inactive</div>
          </div>
          <div className="team-list">
            <PersonCard is_active={true} role={"CEO 개발자"} name={"박원빈"} name_eng={"Wonbin Park"}
                        job={"풀스택, UI/UX 디자인 \n Flutter, NestJS, ..."} githubId={"Park-Wonbin"}/>
            <PersonCard is_active={false} role={"COO 개발자"} name={"박한빛"} name_eng={"David Park"}
                        job={"프론트, 알고리즘 \n C/C++, Flutter, ..."} githubId={"epdlqlc"}/>
            <PersonCard is_active={false} role={"CTO 개발자"} name={"윤석상"} name_eng={"Joseph Yoon"}
                        job={"풀스택, 서버 관리 \n Java, Swift, ..."} githubId={"jyoonPro"}/>
            <PersonCard is_active={false} role={"CMO(Mobile) 개발자"} name={"성치호"} name_eng={"Chiho Seong"}
                        job={"모바일 \n C/C++, Flutter, ..."} githubId={"chseong02"}/>
            <PersonCard is_active={true} role={"iCTO 개발자"} name={"이윤규"} name_eng={"Yunkyu Lee"}
                        job={"프론트, AWS \n Rust, Python, ..."} githubId={"rocketll"}/>
            <PersonCard is_active={true} role={"개발자"} name={"신동훈"} name_eng={"Donghun Shin"}
                        job={"프론트 \n JavaScript, ReactJS, ..."} githubId={"sdh728"}/>
            <PersonCard is_active={true} role={"개발자"} name={"손량"} name_eng={"Ryang Sohn"}
                        job={"백엔드 \n Rust, Golang, ..."} githubId={"sohnryang"}/>
            <PersonCard is_active={true} role={"개발자"} name={"강태영"} name_eng={"Taeyoung Kang"}
                        job={"백엔드 \n NestJS, ReactJS, ..."} githubId={"tytk18"}/>
            <div className="column-3">
              <div className="name-card">
                <div className="card-content" style={{textAlign: 'center', background: '#fff'}}>
                  <div className="nc-name" style={{fontSize: '1.5em', padding: '2.125rem 0'}}>Who's Next?</div>
                  <br/>
                  <a href={`https://github.com/poapper-inc`}><i className="fab fa-github"/> GitHub</a>
                </div>
              </div>
            </div>
          </div>



        </div>
      </div>
    );
  }
}

