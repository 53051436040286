import React, {Component} from 'react';

export default class Partner extends Component {

  render() {
    return (
      <div className="container" id="partner">
        <div className="inner-container">
          <div className="divider"></div>
          <h2>파트너</h2>
          <div className="divider"></div>

          <p style={{textAlign: "center", wordBreak: "keep-all"}}>
            포애퍼와 협업하는 기관 및 단체입니다.</p>
          
          <div className="partner-list">
              <img className="partner-icon" src="img/postech-ds-logo.svg"/>
          </div>

          <div className="column" style={{margin: '0 auto'}}>
            <div className="card" style={{color: '#050f2c'}}>
                <div className="card-content">
                <div className="h-divider" style={{margin: '2rem auto', backgroundColor: '#050f2c'}}></div>

                  <div className="card-title">
                    제휴 및 제안 문의
                  </div>
                  <div>
                    support@poapper.com
                  </div>
                  <div className="h-divider" style={{margin: '2rem auto 0', backgroundColor: '#050f2c'}}></div>

                  <img className="card-img" src="img/message.svg"/>
                </div>
              </div>
          </div>
        </div>
      </div>
    );
  }
}