import React, {Component} from 'react';
import Navbar from "./Navbar/Navbar";
import HomeImage from "./HomeImage/HomeImage";
import Introduce from "./Introduce/Introduce";
import Service from './Product/Service';
import Footer from './Footer/Footer';
import Partner from './Partner/Partner';
import smoothscroll from 'smoothscroll-polyfill';

export default class App extends Component {
  
  render() {    
    smoothscroll.polyfill();
    
    return (
      <section>
        <Navbar/>
        <HomeImage/>
        <Introduce/>
        <div className="section-divider"></div>
        <Service/>
        <div className="section-divider"></div>
        <Partner/>
        {/* <img className="footer-wave" src="img/wave-footer.svg"/> */}
        <Footer/>
      </section>
    );
  }
}

