import React, {Component} from 'react';

export default class Navbar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    }
  }

  scrollToTop = (event) => {
    window.scrollTo({top: 0, behavior:'smooth'});
  }
  scrollToIntro = (event) => {
    window.scrollTo({top: document.querySelector("#intro").offsetTop - 88, behavior:'smooth'});
  }

  scrollToService = (event) => {
    window.scrollTo({top: document.querySelector("#service").offsetTop - 88, behavior:'smooth'});
  }
  
  scrollToPartner = (event) => {
    window.scrollTo({top: document.querySelector("#partner").offsetTop - 88, behavior:'smooth'});
  }

  showMobileNav = () => {
   var mNav = document.querySelector('.mobile-nav');
   mNav.style.display = "";

    const { isOpen } = this.state;
    this.setState({
      isOpen: !isOpen
    })
  }

  render() {
    return (
      <div>
        <div className="nav" style={{flexDirection: "column"}}>
          <div style={{display: "flex"}}>
            <div className="container grid">
              <a onClick={this.scrollToTop}>
                <img className="nav-logo" height="42" src="img/poapper-logo.svg"/>
              </a>
              <ul className="menu" style={{marginLeft: "auto", padding: "0"}}>
                <li className="nav-item p-red" onClick={this.scrollToIntro}><span>포애퍼</span></li>
                <li className="nav-item p-yellow" onClick={this.scrollToService}><span>서비스</span></li>
                <li className="nav-item p-green" onClick={this.scrollToPartner}><span>파트너</span></li>
                <li className="nav-item p-blue" onClick={() => {
                  window.location.href = "https://www.notion.so/d53bc238bd1d48ef982849d9ee20098a"
                }}><span>인재영입 <i className="fas fa-arrow-circle-right"></i></span></li>
              </ul>
              <a className="nav-icon" href="https://www.instagram.com/poapper/" style={{marginLeft: "auto"}}>
                <i className="fab fa-instagram"></i></a>
              <a className="nav-icon" href="https://www.facebook.com/PoApper/"><i className="fab fa-facebook-f"></i></a>
              <div className="mobile-icon" onClick={this.showMobileNav}><i className="fas fa-bars"></i></div>
            </div>
          </div>
          {/*<div style={{height: '46px', justifyContent: 'center', alignItems: 'center', display: 'flex', background: '#eaeaea', borderTop: '1px solid #eaeaea'}}>*/}
          {/*  <a href={'https://quilted-ounce-173.notion.site/cbf62dd8bcfc4c2ebf288f30ffac8faf'} style={{textDecoration: 'none', color: '#000', textAlign: 'center'}}>(주)포애퍼 임시주주총회(9.25) 소집공고 <i className="fas fa-arrow-circle-right"></i></a>*/}
          {/*</div>*/}
        </div>
        <div className={"mobile-nav " + (this.state.isOpen ? 'slide-in-top' : 'slide-out-top')} style={{display: 'none', marginTop: '0px'}}>
          <ul style={{margin: "0 auto", padding: "0"}}>
              <li className="nav-item p-red m-item" onClick={() => {this.scrollToIntro(); this.showMobileNav();}}><span>포애퍼</span></li>
              <li className="nav-item p-yellow m-item" onClick={() => {this.scrollToService(); this.showMobileNav();}}><span>서비스</span></li>
              <li className="nav-item p-green m-item" onClick={() => {this.scrollToPartner(); this.showMobileNav();}}><span>파트너</span></li>
              <li className="nav-item p-blue m-item" onClick={() => {window.location.href="https://www.notion.so/d53bc238bd1d48ef982849d9ee20098a"}}><span>인재영입 <i className="fas fa-arrow-circle-right"></i></span></li>
            </ul>
        </div>
      </div>
    );
  }
}

